import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import ListadeServicosAdicionais from './componentes-servicosextras/listadeservicosadicionais';
import AdicionarServicosExtraAReserva from './componentes-servicosextras/adicionarservicosextraareserva';
import PagamentoServiçosExtradaReserva from './componentes-servicosextras/pagamentoservicosextradareserva';
import EliminarServicosExtra from './componentes-servicosextras/eliminarervicosextra';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


const ServicosExtras = ({
    dadodareserva,
    idDaReservaPost,
    isTabletMobile,
    userRole,
    id,
    setDataReserva,
    cancelamentodareserva,
    userMain,
    setLogsData,
    extraDataServicosReserva,
    setExtraDataServicosReserva
}) => {
    const [servicosExtraPagoReservaSelecionados, setServicosExtraPagoReservaSelecionados] = useState([]);

    //obter servicos 
    const [extraServicosReserva, setExtraServicosReserva] = useState([]);
    const [extraServicosReservaAll, setExtraServicosReservaAll] = useState([]);
    // console.log('extraServicosReserva',extraServicosReserva)


    //OBTER TODOS OS SERVICOS/PRODUTOS
    useEffect(() => {

        if(idDaReservaPost !== undefined){
            axios
                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_extra_services/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                .then((response) => {

                    const servicos = response.data.servicos;
                    setExtraServicosReserva(servicos);

                })
                .catch((error) => {
                    console.error('Erro:', error);
                });



            axios
                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/servicos_extra_reserva/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                .then((response) => {

                    const servicosData = response.data;
                    setExtraDataServicosReserva(servicosData);

                })
                .catch((error) => {
                    console.error('Erro:', error);
                });
            
            axios
                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_extra_services_reserva/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                .then((response) => {
                    const servicosDataAll = response.data;
                    setExtraServicosReservaAll(servicosDataAll);

                })
                .catch((error) => {
                    console.error('Erro:', error);
                });

        }


    }, [dadodareserva])

    // //OBTER TODOS OS SERVICOS/PRODUTOS QUE ESTAO DENTRO DA RESERVA
    // useEffect(() => {



    // }, [dadodareserva])



    return (

        <div className='sec-formulario-registos'>

            {extraDataServicosReserva.length > 0 ? (
                <ListadeServicosAdicionais isTabletMobile={isTabletMobile} extraDataServicosReserva={extraDataServicosReserva} />
            ) : null}

            {cancelamentodareserva === "Cancelado" ? (
                <>
                </>
            ) : (
                <>
                    {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                        <>

                            <AdicionarServicosExtraAReserva id={id} setDataReserva={setDataReserva} dadodareserva={dadodareserva} idDaReservaPost={idDaReservaPost} userMain={userMain} setLogsData={setLogsData} setExtraDataServicosReserva={setExtraDataServicosReserva} extraServicosReserva={extraServicosReserva} />

                            {extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0") ? (
                                <PagamentoServiçosExtradaReserva dadodareserva={dadodareserva} idDaReservaPost={idDaReservaPost} userMain={userMain} setLogsData={setLogsData} extraDataServicosReserva={extraDataServicosReserva} setExtraDataServicosReserva={setExtraDataServicosReserva} servicosExtraPagoReservaSelecionados={servicosExtraPagoReservaSelecionados} setServicosExtraPagoReservaSelecionados={setServicosExtraPagoReservaSelecionados} />
                            ) : null}

                            {extraServicosReservaAll.length > 0 && dadodareserva['meiodepagamento'] == 'Pagamento no Local' ? (
                                <EliminarServicosExtra 
                                id={id} 
                                setDataReserva={setDataReserva} 
                                dadodareserva={dadodareserva} 
                                idDaReservaPost={idDaReservaPost} 
                                userMain={userMain} 
                                setLogsData={setLogsData} 
                                setExtraDataServicosReserva={setExtraDataServicosReserva} 
                                extraServicosReservaAll={extraServicosReservaAll}
                                />
                            ) : null}
                        </>
                    ) : null}
                </>
            )}

        </div>
    );
};

export default ServicosExtras;


