import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, Select } from 'antd';
import { LoadingMsg } from '../../mensagens-alertas/loading';
import { AlertaMsg } from '../../mensagens-alertas/mensagemdealerta';
import { ErroMsg } from '../../mensagens-alertas/mensagemdeerro';
import { SucessoMsg } from '../../mensagens-alertas/mensagemdesucesso';
import { FaPlusCircle , FaMinusCircle} from 'react-icons/fa';

const { Option } = Select;

const EliminarServicosExtra = ({
    idDaReservaPost,
    userMain,
    id,
    setDataReserva,
    setLogsData,
    setExtraDataServicosReserva,
    extraServicosReservaAll,
}) => {
    

    //loadings de adicionar servico extra da reserva
    const [isLoadingServicoExtradaReserva, setIsLoadingServicoExtradaReserva] = useState(false);
    const [isSucessoServicoExtradaReserva, setIsSucessoServicoExtradaReserva] = useState(false);
    const [isAlertaServicoExtradaReserva, setIsAlertaServicoExtradaReserva] = useState(false);
    const [isFalhaServicoExtradaReserva, setIsFalhaServicoExtradaReserva] = useState(false);

    const [alertaMensagem, setAlertaMensagem] = useState("");

    const [servicosExtraReservaSelecionados, setServicosExtraReservaSelecionados] = useState([]);

    const [postcriadoServicoExtradaReserva, setPostCriadoServicoExtradaReserva] = useState(false);
    const [ajustesNaRecolha, setAjustesNaRecolha] = useState("");
    const [isModalOpenAdicionarServicoAdicional, setIsModalOpenAdicionarServicoAdicional] = useState(false);



    //obter dados do funionario
    const dadosdofuncionarioparalogs = userMain?.data || {};



    const handleAjustedaReserva = (e) => {
        setAjustesNaRecolha(e.target.value);
    };


    const handleOpenModalAdicionarServicoAdicional = async () => {
        setIsModalOpenAdicionarServicoAdicional(true);
        setIsSucessoServicoExtradaReserva(false);
        setIsLoadingServicoExtradaReserva(false);
        setIsAlertaServicoExtradaReserva(false);
        setIsFalhaServicoExtradaReserva(false);
        setServicosExtraReservaSelecionados("");
        setAjustesNaRecolha("");
    };


    const handleOkAdicionarServicoAdicionarl = () => {
        setPostCriadoServicoExtradaReserva(true);

        setIsSucessoServicoExtradaReserva(false);
        setIsLoadingServicoExtradaReserva(true);
        setIsAlertaServicoExtradaReserva(false);
        setIsFalhaServicoExtradaReserva(false);


        if (!postcriadoServicoExtradaReserva) {
            if (servicosExtraReservaSelecionados.length === 0 && ajustesNaRecolha === "") {
                setIsSucessoServicoExtradaReserva(false);
                setIsLoadingServicoExtradaReserva(false);
                setIsAlertaServicoExtradaReserva(true);
                setIsFalhaServicoExtradaReserva(false);
                setPostCriadoServicoExtradaReserva(false);
                setAlertaMensagem('escolhe o serviço.');
                return

            } else if (servicosExtraReservaSelecionados.length !== 0 && ajustesNaRecolha === "") {
                setIsSucessoServicoExtradaReserva(false);
                setIsLoadingServicoExtradaReserva(false);
                setIsAlertaServicoExtradaReserva(true);
                setIsFalhaServicoExtradaReserva(false);
                setPostCriadoServicoExtradaReserva(false);
                setAlertaMensagem("adiciona uma descrição");
                return

            } else if (servicosExtraReservaSelecionados.length === 0 && ajustesNaRecolha !== "") {
                setIsSucessoServicoExtradaReserva(false);
                setIsLoadingServicoExtradaReserva(false);
                setIsAlertaServicoExtradaReserva(true);
                setIsFalhaServicoExtradaReserva(false);
                setPostCriadoServicoExtradaReserva(false);
                setAlertaMensagem('escolhe o serviço.');
                return

            } else {

                const { produto_id } = servicosExtraReservaSelecionados;

                let enviarservicosadicionais

                const ajusteDaReservaLabel = `${servicosExtraReservaSelecionados.description} - ${ajustesNaRecolha}`;
                enviarservicosadicionais = [{
                    tipo: "remove",
                    id_reserva: idDaReservaPost,
                    produto_id: produto_id,
                    description: ajusteDaReservaLabel,
                    name: servicosExtraReservaSelecionados.description,
                    extra: servicosExtraReservaSelecionados.extra,
                    price: servicosExtraReservaSelecionados.price,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }];


                axios
                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/remove_servicos_extra_reserva', enviarservicosadicionais)
                    .then(response => {
                        const modeValidation = response.data.validation;
                        if (modeValidation === "valid") {
                            
                            setIsSucessoServicoExtradaReserva(true)
                            setIsLoadingServicoExtradaReserva(false)
                            setIsAlertaServicoExtradaReserva(false)
                            setIsFalhaServicoExtradaReserva(false)

                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/servicos_extra_reserva/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    const servicosData = response.data;
                                    setExtraDataServicosReserva(servicosData);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                });


                                let obterdatareservas;

                                obterdatareservas = {
                                    hash: id,
                                }

                                axios
                                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                    .then(response => {
                                        const detalhesDaReserva = response.data
                                        setDataReserva(detalhesDaReserva)
                                    })
                                    .catch(error => {
                                        console.error('Erro:', error);
                                    })



                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)
                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                });
                                

                            setTimeout(() => {
                                setIsModalOpenAdicionarServicoAdicional(false);
                                setPostCriadoServicoExtradaReserva(false);
                            }, 1500);


                        } else {
                            setIsSucessoServicoExtradaReserva(false)
                            setIsLoadingServicoExtradaReserva(false)
                            setIsAlertaServicoExtradaReserva(false)
                            setIsFalhaServicoExtradaReserva(true)
                            setPostCriadoServicoExtradaReserva(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoServicoExtradaReserva(false)
                        setIsLoadingServicoExtradaReserva(false)
                        setIsAlertaServicoExtradaReserva(false)
                        setIsFalhaServicoExtradaReserva(true)
                        setPostCriadoServicoExtradaReserva(false);
                    })
            }
        } else {
            setIsSucessoServicoExtradaReserva(false);
            setIsLoadingServicoExtradaReserva(false);
            setIsAlertaServicoExtradaReserva(true);
            setIsFalhaServicoExtradaReserva(false);
            setPostCriadoServicoExtradaReserva(false);
        }
    };

    const handleCancelAdicionarServicoAdicionarl = () => {
        setIsModalOpenAdicionarServicoAdicional(false);
    };

    return (


        <div className='admin-lab'>
            <div className='sec-servicosextrareserva'>
                <h3>Remover Serviços:</h3>
                <Button className="admin-form-button-n" type="primary" onClick={handleOpenModalAdicionarServicoAdicional}>
                    <FaMinusCircle style={{ marginRight: '8px' }} /> Remover Serviço
                </Button>
                <Modal title="Remover Serviços da Reserva:" open={isModalOpenAdicionarServicoAdicional} onOk={handleOkAdicionarServicoAdicionarl} onCancel={handleCancelAdicionarServicoAdicionarl} okText="Confirmar" cancelText="Cancelar">
                    <div className="modal-com-flex">
                        <div>
                            <span>Lista de Serviços</span>

                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecione os serviços"
                                value={servicosExtraReservaSelecionados ? servicosExtraReservaSelecionados.description : null}
                                onChange={(value) => {
                                    const selectedService = extraServicosReservaAll.find(servico => servico.description === value);
                                    setServicosExtraReservaSelecionados(selectedService);
                                }}
                            >
                                {extraServicosReservaAll.map((servico, index) => (
                                    <Option key={index} value={servico.description}>
                                        {servico.description}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <span>Adicionar Descrição</span>
                            <input
                                type="input"
                                placeholder='Descreve o serviço...'
                                className="input-calendario ajustar-reserva-input"
                                value={ajustesNaRecolha}
                                onChange={handleAjustedaReserva}
                            />
                        </div>
                        {isLoadingServicoExtradaReserva ? (
                            <>
                                <LoadingMsg mensagem="Aguarde..." />
                            </>
                        ) : isAlertaServicoExtradaReserva ? (
                            <>
                                <AlertaMsg mensagem={alertaMensagem} />
                            </>
                        ) : isFalhaServicoExtradaReserva ? (
                            <>
                                <ErroMsg mensagem="tente novamente." />
                            </>
                        ) : isSucessoServicoExtradaReserva ? (
                            <>
                                <SucessoMsg mensagem="serviço/os removido/os" />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </div>
        </div>

    );
};

export default EliminarServicosExtra;