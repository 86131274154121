import React, { useEffect, useState, useRef } from "react";
import { Button, Image, Table, Tabs } from 'antd';
import axios from "axios";
import { CloseCircleOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import configAdmin from "../../config-admin/config-admin";
import WPAPI from 'wpapi';
import { LoadingMsg } from "../mensagens-alertas/loading";
import { AlertaMsg } from "../mensagens-alertas/mensagemdealerta";
import { ErroMsg } from "../mensagens-alertas/mensagemdeerro";
import { SucessoMsg } from "../mensagens-alertas/mensagemdesucesso";
import { PesoMsg } from "../mensagens-alertas/mensagemdepeso";
import Resizer from "react-image-file-resizer";
// import { Spin } from 'antd';
// import { BiError } from "react-icons/bi";
// import { fabClasses } from "@mui/material";
// import { TramOutlined } from "@mui/icons-material";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);



const AreaGestaoReservaAdmin = ({
    videoooos,
    fotografiassss,
    idDaReservaPost,
    dadodareserva,
    isTabletMobile,
    localdeestacionamentodata,
    id,
    cancelamentodareserva,
    userMain,
    logsData,
    setLogsData,
    setDataReserva
}) => {


    // console.log(fotografiassss)


    //obter dados do funionario
    const userFromLocalStorage = localStorage.getItem('user');
    const userRole = userMain?.roles[0]
    const userNameAccount = userMain?.data.user_nicename
    const dadosdofuncionarioparalogs = userMain?.data



    //Local de Estaciomento
    const [historicolocaldeestacionamento, setHistoricoLocaldeEstacionamento] = useState(''); //historico atualizado
    const [localdeestacionamentoenviado, setlocaldeestacionamentoenviado] = useState(false); //para validar se o local de estacionamento foi enviado
    //loadings dLocal de Estaciomento
    const [isLoadingLocalEstacionamento, setIsLoadingLocalEstacionamento] = useState(false);
    const [isSucessoLocalEstacionamento, setIsSucessoLocalEstacionamentoa] = useState(false);
    const [isAlertaLocalEstacionamento, setIsAlertaLocalEstacionamento] = useState(false);
    const [isFalhaLocalEstacionamento, setIsFalhaLocalEstacionamento] = useState(false);



    //Imagens
    const [selectedFile, setSelectedFile] = useState(''); //ficheiros
    const [conjuntodeimagensids, setConjuntodeimagensids] = useState([]); //conjunto de imagens - ids
    const [conjuntodeimagens, setConjuntodeimagens] = useState([]); //conjunto de imagens - url
    //Imagens-LOADING
    const [isLoadingFotos, setIsLoadingFotos] = useState(false); //loading fotos
    const [isFalhaFotos, setIsFalhaFotos] = useState(false); //falha fotos
    const [isSucessoFotos, setIsSucessoFotos] = useState(false);
    const [isAlertaFotos, setIsAlertaFotos] = useState(false);



    //Videos
    const [selectedFilevideo, setSelectedFilevideo] = useState(''); //ficheiros
    const [conjuntodevideo, setConjuntodevideo] = useState([]); //conjunto de imagens - url
    const [conjuntodevideoids, setConjuntodevideoids] = useState([]); //conjunto de imagens - ids
    //Videos-LOADING
    const [isLoadingVideos, setIsLoadingVideos] = useState(false);
    const [isSucessoVideos, setIsSucessoVideos] = useState(false);
    const [isAlertaVideos, setIsAlertaVideos] = useState(false);
    const [isFalhaVideos, setIsFalhaVideos] = useState(false);
    const [isPesodeCarregamentoVideos, setIsPesodeCarregamentoVideos] = useState(false);




    const inputRef = useRef(null);


    useEffect(() => {
        if (fotografiassss && fotografiassss.length > 0 && conjuntodeimagens.length === 0) {

            const imageUrls = fotografiassss.map((foto) => foto.url);
            const arr_ids_fotos = fotografiassss.map((foto) => foto.id);

            setConjuntodeimagensids(arr_ids_fotos);
            setConjuntodeimagens(imageUrls);

            // console.log('arr_ids_fotos', arr_ids_fotos)
            // console.log('imageUrls', imageUrls)
        }
    }, [fotografiassss, conjuntodeimagens]);


    useEffect(() => {
        if (videoooos && videoooos.length > 0 && conjuntodevideo.length === 0) {

            const videoUrls = videoooos?.map((video) => video.url);
            const arr_ids_video = videoooos?.map((video) => video.id);

            setConjuntodevideoids(arr_ids_video)
            setConjuntodevideo(videoUrls);
        }
    }, [videoooos, conjuntodevideo]);



    //OBTER LOGS DA RESERVA
    // console.log(idDaReservaPost)
    useEffect(() => {

        if(idDaReservaPost !== undefined){
            axios
                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                .then((response) => {
                    // console.log(response)

                    const alllogsData = response.data.map((novadata) => {

                        const dataEmPartes = novadata.date.split(' ');
                        const dataPartes = dataEmPartes[0].split('-');
                        const horaPartes = dataEmPartes[1].split(':');
                        const diaHistorico = dataPartes[2];
                        const mesHistorico = dataPartes[1];
                        const anoHistorico = dataPartes[0];
                        const hora = horaPartes[0];
                        const minutos = horaPartes[1];
                        const segundos = horaPartes[2];

                        const formattedDate = `${diaHistorico}/${mesHistorico}/${anoHistorico} ${hora}:${minutos}:${segundos}`;

                        return {
                            ...novadata,
                            formattedDate,
                        };
                    });

                    // console.log(alllogsData)

                    setLogsData(alllogsData);
                    // console.log('logs', logsData, '', idDaReservaPost)
                })
                .catch((error) => {
                    console.error('Erro:', error);
                });
        }

    }, [dadodareserva]);


    //IMAGENS VIATURAS   ---------------
    const handleFileInputChange = (e) => {
        setSelectedFile(e.target.files);
    };


    // Func resize & compress file
    const resizeFile = (file) => new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            800,
            800,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

    // Transformar imagem resized em blob para upload
    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
      
        return new Blob([ia], { type: mimeString });
      };


    const handleEnviarFotos = async () => {

        if (selectedFile.length < 1) {
            setIsAlertaFotos(true)
            setIsLoadingFotos(false);
            setIsSucessoFotos(false);
            setIsFalhaFotos(false);

            return;

        } else {

            setIsAlertaFotos(false)
            setIsLoadingFotos(true);
            setIsSucessoFotos(false);
            setIsFalhaFotos(false);

            const headers = new Headers(configAdmin.headers);

            // Acessos
            const wp = new WPAPI({
                endpoint: configAdmin.wpApi.endpoint,
                username: configAdmin.wpApi.username,
                password: configAdmin.wpApi.password,
                headers: headers
            });

            //cria post no wordpress na mutimedia
            const createPost = async (file) => {
                const filePath = file;
                // console.log("filePath", filePath)

                try {

                    const uploadImagem = await wp.media().file(filePath).create({
                        title: ` #${idDaReservaPost} - ${dadodareserva.marca} ${dadodareserva.modelo} - ${dadodareserva.matricula}`,
                    });

                    // console.log('uploadImagem', uploadImagem)

                    conjuntodeimagens.push(uploadImagem.source_url);
                    setConjuntodeimagens([...conjuntodeimagens]);

                    return uploadImagem.id;

                } catch (error) {
                    console.error('Erro:', error);

                    return null;
                }
            };

            wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');


            try {
                for (let i = 0; i < selectedFile.length; i++) {
                    const file = selectedFile[i];

                    // Process Image
                    const image_client = await resizeFile(file);
                    const Blob_processed = dataURIToBlob(image_client);
                    const newFile_processed = new File([Blob_processed], selectedFile[i]['name']);

                    const uploadimagemId = await createPost(newFile_processed);
                    conjuntodeimagensids.push(uploadimagemId);

                    // console.log("file)", file);
                    // console.log("selectedFile", selectedFile);
                    // console.log("uploadimagemId", uploadimagemId);
                    // console.log("conjuntodeimagensids", conjuntodeimagensids);
                }

                setConjuntodeimagensids(conjuntodeimagensids);

                if (conjuntodeimagensids.length > 0) {

                    const atualizar = await wp.reservas().id(idDaReservaPost).update({
                        acf: {
                            fotografiascarros: conjuntodeimagensids,
                        },
                    });

                    // console.log('atualizar', atualizar)

                    setConjuntodeimagens([...conjuntodeimagens]);
                    // console.log('conjuntodeimagens', conjuntodeimagens)
                    setSelectedFile([]);


                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }


                    let enviarlogsdata;

                    enviarlogsdata = {
                        id_reserva: idDaReservaPost,
                        description: `Adicionou imagens à reserva`,
                        tipo: "upload",
                        user_id: dadosdofuncionarioparalogs.ID,
                        user_name: dadosdofuncionarioparalogs.display_name,
                    };

                    axios
                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/post_log', enviarlogsdata)
                        .then(response => {
                            // console.log(response)
                            const modeValidation = response.data.validation;
                            if (modeValidation === "valid") {
                                // console.log('enviou:');

                                let obterdatareservas;
                                obterdatareservas = {
                                    hash: id,
                                }

                                //obter dados da reserva
                                axios
                                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                    .then(response => {
                                        const detalhesDaReserva = response.data
                                        setDataReserva(detalhesDaReserva)
                                    })
                                    .catch(error => {
                                        console.error('Erro:', error);
                                    })


                                //get todos os logs 
                                axios
                                    .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                    .then((response) => {
                                        const alllogsData = response.data;
                                        setLogsData(alllogsData);
                                    })
                                    .catch((error) => {
                                        console.error('Erro:', error);
                                    });


                            } else {
                                console.error('Erro:');
                            }



                            setIsAlertaFotos(false)
                            setIsLoadingFotos(false);
                            setIsSucessoFotos(true);
                            setIsFalhaFotos(false);
                            setTimeout(() => {
                                setIsAlertaFotos(false)
                                setIsLoadingFotos(false);
                                setIsSucessoFotos(false);
                                setIsFalhaFotos(false);
                            }, 1500);



                        })
                        .catch(error => {
                            console.error('Erro:', error);
                            setIsAlertaFotos(false)
                            setIsLoadingFotos(false);
                            setIsSucessoFotos(false);
                            setIsFalhaFotos(true);
                        })


                } else {
                    setIsAlertaFotos(false)
                    setIsLoadingFotos(false);
                    setIsSucessoFotos(false);
                    setIsFalhaFotos(true);
                }


            } catch (error) {
                console.error('Erro:', error);
                setIsAlertaFotos(false)
                setIsLoadingFotos(false);
                setIsSucessoFotos(false);
                setIsFalhaFotos(true);
            }
        };
    };

    //---------------





    //LOCAL DE ESTACIONAMENTO   ---------------

    const handleLocalEstacionamento = (e) => {
        setHistoricoLocaldeEstacionamento(e.target.value);
    };


    // Função para atualizar o histórico
    const handleAtualizarLocal = async () => {

        setlocaldeestacionamentoenviado(true)
        setIsLoadingLocalEstacionamento(true);

        if (historicolocaldeestacionamento === "" || historicolocaldeestacionamento === null) {

            setIsLoadingLocalEstacionamento(false);
            setIsSucessoLocalEstacionamentoa(false);
            setIsAlertaLocalEstacionamento(true);
            setIsFalhaLocalEstacionamento(false);
            setlocaldeestacionamentoenviado(false)

        } else {

            // const dataAtual = new Date();
            // const diaHoraAtual = dataAtual.toLocaleString('pt-PT');
            // const historicoFormatoFrase = `Funcionário: ${userNameAccount} | Data e Hora: ${diaHoraAtual} | Local: ${localdeestacionamento}`;

            // const novoHistorico = [historicoFormatoFrase, ...historicodemovimentacoesdataArray];

            try {

                const headers = new Headers(configAdmin.headers);
                const wp = new WPAPI({
                    endpoint: configAdmin.wpApi.endpoint,
                    username: configAdmin.wpApi.username,
                    password: configAdmin.wpApi.password,
                    headers: headers
                });

                wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');

                const atualizar = await wp.reservas().id(idDaReservaPost).update({
                    acf: {
                        localdeestacionamentodaviatura: historicolocaldeestacionamento,
                    },
                });

                // setHistoricodeMovimentacoesdataArray(novoHistorico);

                let enviarlogsdata;

                enviarlogsdata = {
                    id_reserva: idDaReservaPost,
                    description: `Atualizou o Local de Estacionamento para: ${historicolocaldeestacionamento}`,
                    tipo: "local",
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                    update: `${historicolocaldeestacionamento}`,
                    motivo: ""
                };

                axios
                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/post_log', enviarlogsdata)
                    .then(response => {
                        const modeValidation = response.data.validation;
                        if (modeValidation === "valid") {
                            // console.log('enviou:');

                            let obterdatareservas;
                            obterdatareservas = {
                                hash: id,
                            }

                            axios
                                .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                .then(response => {
                                    const detalhesDaReserva = response.data
                                    // console.log(detalhesDaReserva)
                                    setDataReserva(detalhesDaReserva)
                                })
                                .catch(error => {
                                    console.error('Erro:', error);
                                })


                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)
                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                });

                        } else {
                            console.error('Erro:');
                        }


                        setIsLoadingLocalEstacionamento(false);
                        setIsSucessoLocalEstacionamentoa(true);
                        setIsAlertaLocalEstacionamento(false);
                        setIsFalhaLocalEstacionamento(false);
                        setlocaldeestacionamentoenviado(false)

                        setTimeout(() => {
                            setIsLoadingLocalEstacionamento(false);
                            setIsSucessoLocalEstacionamentoa(false);
                            setIsAlertaLocalEstacionamento(false);
                            setIsFalhaLocalEstacionamento(false);
                            setlocaldeestacionamentoenviado(false)
                        }, 1500);

                    })
                    .catch(error => {
                        setIsLoadingLocalEstacionamento(false);
                        setIsSucessoLocalEstacionamentoa(false);
                        setIsAlertaLocalEstacionamento(false);
                        setIsFalhaLocalEstacionamento(true);
                        setlocaldeestacionamentoenviado(false)
                        console.error('Erro:', error);
                    })


            } catch (error) {
                setIsLoadingLocalEstacionamento(false);
                setIsSucessoLocalEstacionamentoa(false);
                setIsAlertaLocalEstacionamento(false);
                setIsFalhaLocalEstacionamento(true);
                setlocaldeestacionamentoenviado(false)
                console.error('Erro:', error);
            }
        }
    };

    //---------------









    const handleFileVideoInputChange = async (e) => {
        setSelectedFilevideo(e.target.files);
    }

    const handleEnviarVideos = async () => {

        if (selectedFilevideo.length < 1) {
            setIsAlertaVideos(true)
            setIsLoadingVideos(false);
            setIsSucessoVideos(false);
            setIsFalhaVideos(false);

            return;

        } else {

            setIsAlertaVideos(false)
            setIsLoadingVideos(true);
            setIsSucessoVideos(false);
            setIsFalhaVideos(false);

            const headers = new Headers(configAdmin.headers);

            const wp = new WPAPI({
                endpoint: configAdmin.wpApi.endpoint,
                username: configAdmin.wpApi.username,
                password: configAdmin.wpApi.password,
                headers: headers
            });

            //cria post no wordpress na mutimedia
            const createVideo = async (file) => {
                const filePath = file;
                // console.log('filePath', filePath)

                try {
                    const uploadVideo = await wp.media().file(filePath).create({
                        title: `#${idDaReservaPost} - ${dadodareserva.marca} ${dadodareserva.modelo} - ${dadodareserva.matricula}`,
                    });
                    
                    const fetchedVideo = await wp.media().id(uploadVideo.id);
                    const sourceUrl = fetchedVideo.source_url;

                    conjuntodevideo.push(sourceUrl);
                    setConjuntodevideoids([...conjuntodevideo]);

                    return uploadVideo.id;

                } catch (error) {
                    console.error('Erro:', error);

                    return null;
                }
            };

            wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');

            try {
                for (const file of selectedFilevideo) {
                    const uploadVideoId = await createVideo(file);
                    conjuntodevideoids.push(uploadVideoId);
                }

                setConjuntodevideoids(conjuntodevideoids);

                if (conjuntodevideoids.length > 0) {

                    const atualizar = await wp.reservas().id(idDaReservaPost).update({
                        acf: {
                            videocarros: conjuntodevideoids,
                        },
                    });

                    setConjuntodevideo([...conjuntodevideo]);
                    setSelectedFilevideo([]);

                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }


                    let enviarlogsdata;

                    enviarlogsdata = {
                        id_reserva: idDaReservaPost,
                        description: `Adicionou vídeo/os à reserva`,
                        tipo: "upload",
                        user_id: dadosdofuncionarioparalogs.ID,
                        user_name: dadosdofuncionarioparalogs.display_name,
                    };

                    axios
                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/post_log', enviarlogsdata)
                        .then(response => {
                            const modeValidation = response.data.validation;
                            if (modeValidation === "valid") {
                                // console.log('enviou:');

                                let obterdatareservas;
                                obterdatareservas = {
                                    hash: id,
                                }

                                axios
                                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                    .then(response => {
                                        const detalhesDaReserva = response.data
                                        // console.log(detalhesDaReserva)
                                        setDataReserva(detalhesDaReserva)
                                    })
                                    .catch(error => {
                                        console.error('Erro:', error);
                                    })


                                axios
                                    .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                    .then((response) => {
                                        // console.log(response)
                                        const alllogsData = response.data;
                                        setLogsData(alllogsData);
                                    })
                                    .catch((error) => {
                                        console.error('Erro:', error);
                                    });

                            } else {
                                console.error('Erro:');
                            }
                            setIsAlertaVideos(false)
                            setIsLoadingVideos(false);
                            setIsSucessoVideos(true);
                            setIsFalhaVideos(false);
                            setTimeout(() => {
                                setIsAlertaVideos(false)
                                setIsLoadingVideos(false);
                                setIsSucessoVideos(false);
                                setIsFalhaVideos(false);
                            }, 1500);
                        })
                        .catch(error => {
                            console.error('Erro:', error);
                            setIsAlertaVideos(false)
                            setIsLoadingVideos(false);
                            setIsSucessoVideos(false);
                            setIsFalhaVideos(true);
                        })


                } else {
                    console.error('Erro');
                    setIsAlertaVideos(false)
                    setIsLoadingVideos(false);
                    setIsSucessoVideos(false);
                    setIsFalhaVideos(true);
                }
            } catch (error) {
                console.error('Erro:', error);
                setIsAlertaVideos(false)
                setIsLoadingVideos(false);
                setIsSucessoVideos(false);
                setIsFalhaVideos(true);
            }
        }



    };



    const handleEliminarFoto = (event) => {
        // alert('Eliminado');

        // console.log(event)
        const idDaImagemElimar = event
        const headers = new Headers(configAdmin.headers);

        // Acessos
        const wp = new WPAPI({
            endpoint: configAdmin.wpApi.endpoint,
            username: configAdmin.wpApi.username,
            password: configAdmin.wpApi.password,
            headers: headers
        });

        wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');

        try {
            // if (novoEstadodaReserva) {

            let enviarlogsdata;


            enviarlogsdata = {
                media_id: idDaImagemElimar,
                media_type: "fotografiascarros",
                user_id: dadosdofuncionarioparalogs.ID,
                user_name: dadosdofuncionarioparalogs.display_name,
                id_reserva: idDaReservaPost,
            }

            axios
                .post('https://app.indoorparkinglowcost.pt/wp-json/api/delete_media_reserva', enviarlogsdata)
                .then(response => {

                    // console.log('enviarlogsdata',enviarlogsdata)

                    const modeValidation = response.data.validation;
                    if (modeValidation === "valid") {

                        // remove image from list
                        var index_of_imgIds = conjuntodeimagensids.indexOf(idDaImagemElimar);
                        if (index_of_imgIds !== -1) {
                            conjuntodeimagensids.splice(index_of_imgIds, 1);
                        }

                        let obterdatareservas;
                        obterdatareservas = {
                            hash: id,
                        }

                        axios
                            .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                            .then(response => {
                                const detalhesDaReserva = response.data
                                // console.log(detalhesDaReserva)
                                setDataReserva(detalhesDaReserva)
                            })
                            .catch(error => {
                                console.error('Erro:', error);
                            })


                        axios
                            .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                            .then((response) => {
                                // console.log(response)
                                const alllogsData = response.data;
                                setLogsData(alllogsData);
                            })
                            .catch((error) => {
                                console.error('Erro:', error);
                            });

                    } else {
                        console.error('Erro:');
                    }

                })
                .catch(error => {
                    console.error('Erro:', error);
                })


            // } else {
            //     console.log('Erro');
            // }
        } catch (error) {
            console.error('Erro:', error);
        }
    }





    const handleEliminarVideos = (event) => {
        // alert('Eliminado');

        // console.log(event)
        const idDoVideoEliminar = event
        const headers = new Headers(configAdmin.headers);

        // Acessos
        const wp = new WPAPI({
            endpoint: configAdmin.wpApi.endpoint,
            username: configAdmin.wpApi.username,
            password: configAdmin.wpApi.password,
            headers: headers
        });

        wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');

        try {
            // if (novoEstadodaReserva) {

            let enviarlogsdata;

            enviarlogsdata = {
                media_id: idDoVideoEliminar,
                media_type: "videocarros",
                user_id: dadosdofuncionarioparalogs.ID,
                user_name: dadosdofuncionarioparalogs.display_name,
                id_reserva: idDaReservaPost,
            }

            axios
                .post('https://app.indoorparkinglowcost.pt/wp-json/api/delete_media_reserva', enviarlogsdata)
                .then(response => {
                    const modeValidation = response.data.validation;
                    if (modeValidation === "valid") {

                        // remove videos from list
                        // var index_of_videoIds = conjuntodevideoids.indexOf(idDoVideoEliminar);
                        // if (index_of_videoIds !== -1) {
                        //     conjuntodevideoids.splice(index_of_videoIds, 1);
                        // }

                        let obterdatareservas;
                        obterdatareservas = {
                            hash: id,
                        }

                        axios
                            .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                            .then(response => {
                                const detalhesDaReserva = response.data
                                // console.log(detalhesDaReserva)
                                setDataReserva(detalhesDaReserva)
                            })
                            .catch(error => {
                                console.error('Erro:', error);
                            })


                        axios
                            .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                            .then((response) => {
                                // console.log(response)
                                const alllogsData = response.data;
                                setLogsData(alllogsData);
                            })
                            .catch((error) => {
                                console.error('Erro:', error);
                            });

                    } else {
                        console.error('Erro:');
                    }

                })
                .catch(error => {
                    console.error('Erro:', error);
                })


            // } else {
            //     console.log('Erro');
            // }
        } catch (error) {
            console.error('Erro:', error);
        }
    }


    //LOGS ORDENAÇAO POR DATA MAIS RECENTE
    logsData.sort((a, b) => new Date(b.date) - new Date(a.date));

    // console.log('logsData', logsData)




    // console.log('fotos', fotografiassss)
    // console.log('conjuntodeimagens', conjuntodeimagens)
    // console.log('conjuntodeimagensids', conjuntodeimagensids)
    // console.log('videos', videoooos)
    // console.log('conjuntodevideo', conjuntodevideo)
    // console.log('conjuntodevideoids', conjuntodevideoids)




    return (
        <>

            {(userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor") ? (
                <>
                    <Tabs>
                        <Tabs.TabPane tab="Estacionamento" key="1">
                            <div className="mainsec-dados-local-estacionamento">
                                {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                                    <>
                                    </>
                                ) : (
                                    <>
                                        <br />
                                        <div className='subcol-dados-local-estacionamento custom-select'>
                                            <input
                                                type="text"
                                                className="input-calendario"
                                                onChange={handleLocalEstacionamento}
                                            />
                                            <div>
                                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleAtualizarLocal}>
                                                    Adicionar
                                                </Button>
                                            </div>
                                        </div>
                                        <br></br>
                                        {isLoadingLocalEstacionamento ? (
                                            <LoadingMsg mensagem="Aguarde..." />
                                        ) : isAlertaLocalEstacionamento ? (
                                            <AlertaMsg mensagem="campo vazio." />
                                        ) : isFalhaLocalEstacionamento ? (
                                            <ErroMsg mensagem="tente novamente." />
                                        ) : isSucessoLocalEstacionamento ? (
                                            <SucessoMsg mensagem="local de estacionamento atualizado!" />
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                                {isTabletMobile ? (
                                    <>
                                        {localdeestacionamentodata ? (
                                            <>
                                                <br></br>
                                                <div className="flex-column">
                                                    <h2>Local de Estaciomento:</h2>
                                                    <span className="label-do-campo">{localdeestacionamentodata}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <p className="text-cinza">Sem registos de local de estacionamento.</p>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {localdeestacionamentodata ? (
                                            <>
                                                <br></br>
                                                <h2>Local de Estaciomento: <span className="label-do-campo">{localdeestacionamentodata}</span></h2>
                                            </>
                                        ) : (
                                            <p className="text-cinza">Sem registos de local de estacionamento.</p>
                                        )}
                                    </>
                                )}
                            </div>
                        </Tabs.TabPane >

                        <Tabs.TabPane tab="Imagens" key="2">
                            <br />
                            {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <div className="inserir-imagens-grupo">
                                        <input type="file" onChange={handleFileInputChange} ref={inputRef} multiple />
                                        <br />
                                        <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleEnviarFotos}>
                                            Adicionar
                                        </Button>

                                        {isLoadingFotos ? (
                                            <LoadingMsg mensagem="Aguarde..." />
                                        ) : isAlertaFotos ? (
                                            <AlertaMsg mensagem="selecione uma imagem." />
                                        ) : isFalhaFotos ? (
                                            <ErroMsg mensagem="tente novamente." />
                                        ) : isSucessoFotos ? (
                                            <SucessoMsg mensagem="imagens adicionadas!" />
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                    <br />
                                    <br />
                                </>
                            )}
                            <div className="grupo-imagens-do-carro">
                                <h2>Fotografias da Viatura:</h2>
                                <hr className="linha-principal" />
                                <br />
                                <div className="imagens-do-carro">
                                    <Image.PreviewGroup>
                                        {Array.isArray(fotografiassss) && fotografiassss.length > 0 ? (
                                            fotografiassss.map((foto) => (
                                                <div className="sec-image-single" key={foto.id}>
                                                    <Image
                                                        height={200}
                                                        className="imagem-do-carro"
                                                        src={foto.url}
                                                        alt={`Imagem ${foto.id}`}
                                                        value={foto.id}
                                                    />
                                                    {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                        <a className="eliminar-imagem" value={foto.id} onClick={() => handleEliminarFoto(foto.id)}><CloseCircleOutlined /></a>
                                                    ) : null}
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-cinza">Sem registos de fotografias.</p>
                                        )}
                                    </Image.PreviewGroup>
                                </div>

                            </div>

                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Vídeos" key="3">

                            <br />
                            {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <div className="inserir-imagens-grupo">
                                        <input type="file" onChange={handleFileVideoInputChange} ref={inputRef} multiple />
                                        <br />
                                        <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleEnviarVideos}>
                                            Adicionar
                                        </Button>


                                        {isLoadingVideos ? (
                                            <LoadingMsg mensagem="Aguarde..." />
                                        ) : isAlertaVideos ? (
                                            <AlertaMsg mensagem="selecione um vídeo." />
                                        ) : isFalhaVideos ? (
                                            <ErroMsg mensagem="tente novamente." />
                                        ) : isSucessoVideos ? (
                                            <SucessoMsg mensagem="vídeo adicionado!" />
                                        ) : isPesodeCarregamentoVideos ? (
                                            <PesoMsg mensagem="Só consegue adicicionar vídeos com a capacidade máximo de 100MB." />
                                        ) : (
                                            <></>
                                        )}


                                    </div>
                                    <br />
                                    <br />
                                </>
                            )}

                            <div className="grupo-videos-do-carro">
                                <h2>Videos da Viatura:</h2>
                                <hr className="linha-principal" />
                                <br />
                                <div className="videos-do-carro-main">
                                    {Array.isArray(videoooos) && videoooos.length > 0 ? (
                                        videoooos.map((video) => (
                                            <>
                                                <div className="sec-image-single">
                                                    <video className="videos-do-carro" controls key={video.id}>
                                                        <source src={video.url} type="video/mp4" />
                                                    </video>
                                                    {(userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor") ? (
                                                        <a className="eliminar-imagem" value={video.id} onClick={() => handleEliminarVideos(video.id)}><CloseCircleOutlined /></a>
                                                    ) : null}
                                                </div>
                                            </>
                                        ))
                                    ) : (
                                        <p className="text-cinza">Sem registos de vídeos.</p>
                                    )}
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Histórico" key="4">
                            <br />
                            <div>
                                {isTabletMobile ? (
                                    <>
                                        <Table
                                            dataSource={logsData}
                                            pagination={{
                                                pageSize: 10,
                                                position: ["bottomCenter"],
                                            }}
                                        >
                                            <Table.Column
                                                title="Nome / Data / Descrição"
                                                dataIndex="user_name"
                                                key="user_name"
                                                render={(user_name, record) => (
                                                    <>
                                                        <strong>Nome:</strong> {user_name}
                                                        <br />
                                                        <strong>Data:</strong> {record.formattedDate}
                                                        <br />
                                                        <strong>Descrição:</strong> {record.description}
                                                    </>
                                                )}
                                            />
                                        </Table>
                                    </>
                                ) : (
                                    <>
                                        <Table
                                            dataSource={logsData}
                                            pagination={{
                                                pageSize: 10,
                                                position: ["bottomCenter"],
                                            }}
                                        >
                                            <Table.Column
                                                title="Nome"
                                                dataIndex="user_name"
                                                key="user_name"
                                                render={(user_name) => (
                                                    <span>{user_name}</span>
                                                )}
                                            />
                                            <Table.Column
                                                title="Data"
                                                dataIndex="formattedDate"
                                                key="formattedDate"
                                                render={(formattedDate) => (
                                                    <span>{formattedDate}</span>
                                                )}
                                            />
                                            <Table.Column
                                                title="Descrição"
                                                dataIndex="description"
                                                key="description"
                                                render={(description) => (
                                                    <span>{description}</span>
                                                )}
                                            />
                                        </Table>

                                    </>
                                )}
                            </div>
                        </Tabs.TabPane>
                    </Tabs >

                </>
            ) : (
                <>

                    <Tabs>
                        {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                            <>
                                <Tabs.TabPane tab="Histórico" key="1">
                                    <br />
                                    <div>
                                        {isTabletMobile ? (
                                            <>
                                                <Table
                                                    dataSource={logsData}
                                                    pagination={{
                                                        pageSize: 10,
                                                        position: ["bottomCenter"],
                                                    }}
                                                >
                                                    <Table.Column
                                                        title="Nome / Data / Descrição"
                                                        dataIndex="user_name"
                                                        key="user_name"
                                                        render={(user_name, record) => (
                                                            <>
                                                                <strong>Nome:</strong> {user_name}
                                                                <br />
                                                                <strong>Data:</strong> {record.formattedDate}
                                                                <br />
                                                                <strong>Descrição:</strong> {record.description}
                                                            </>
                                                        )}
                                                    />
                                                </Table>
                                            </>
                                        ) : (
                                            <>
                                                <Table
                                                    dataSource={logsData}
                                                    pagination={{
                                                        pageSize: 10,
                                                        position: ["bottomCenter"],
                                                    }}
                                                >
                                                    <Table.Column
                                                        title="Nome"
                                                        dataIndex="user_name"
                                                        key="user_name"
                                                        render={(user_name) => (
                                                            <span>{user_name}</span>
                                                        )}
                                                    />
                                                    <Table.Column
                                                        title="Data"
                                                        dataIndex="formattedDate"
                                                        key="formattedDate"
                                                        render={(formattedDate) => (
                                                            <span>{formattedDate}</span>
                                                        )}
                                                    />
                                                    <Table.Column
                                                        title="Descrição"
                                                        dataIndex="description"
                                                        key="description"
                                                        render={(description) => (
                                                            <span>{description}</span>
                                                        )}
                                                    />
                                                </Table>

                                            </>
                                        )}
                                    </div>
                                </Tabs.TabPane>
                            </>
                        ) : (
                            <>
                                <Tabs.TabPane tab="Estacionamento" key="1">
                                    <div className="mainsec-dados-local-estacionamento">
                                        {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                                            <>
                                            </>
                                        ) : (
                                            <>
                                                <br />
                                                <div className='subcol-dados-local-estacionamento custom-select'>
                                                    <input
                                                        type="text"
                                                        className="input-calendario"
                                                        onChange={handleLocalEstacionamento}
                                                    />
                                                    <div>
                                                        <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleAtualizarLocal}>
                                                            Adicionar
                                                        </Button>
                                                    </div>
                                                </div>
                                                <br></br>
                                                {isLoadingLocalEstacionamento ? (
                                                    <LoadingMsg mensagem="Aguarde..." />
                                                ) : isAlertaLocalEstacionamento ? (
                                                    <AlertaMsg mensagem="campo vazio." />
                                                ) : isFalhaLocalEstacionamento ? (
                                                    <ErroMsg mensagem="tente novamente." />
                                                ) : isSucessoLocalEstacionamento ? (
                                                    <SucessoMsg mensagem="local de estacionamento atualizado!" />
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                        {isTabletMobile ? (
                                            <>
                                                {localdeestacionamentodata ? (
                                                    <>
                                                        <br></br>
                                                        <div className="flex-column">
                                                            <h2>Local de Estaciomento:</h2>
                                                            <span className="label-do-campo">{localdeestacionamentodata}</span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <p className="text-cinza">Sem registos de local de estacionamento.</p>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {localdeestacionamentodata ? (
                                                    <>
                                                        <br></br>
                                                        <h2>Local de Estaciomento: <span className="label-do-campo">{localdeestacionamentodata}</span></h2>
                                                    </>
                                                ) : (
                                                    <p className="text-cinza">Sem registos de local de estacionamento.</p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Tabs.TabPane >

                                <Tabs.TabPane tab="Imagens" key="2">
                                    <br />
                                    {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <div className="inserir-imagens-grupo">
                                                <input type="file" onChange={handleFileInputChange} ref={inputRef} multiple />
                                                <br />
                                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleEnviarFotos}>
                                                    Adicionar
                                                </Button>

                                                {isLoadingFotos ? (
                                                    <LoadingMsg mensagem="Aguarde..." />
                                                ) : isAlertaFotos ? (
                                                    <AlertaMsg mensagem="selecione uma imagem." />
                                                ) : isFalhaFotos ? (
                                                    <ErroMsg mensagem="tente novamente." />
                                                ) : isSucessoFotos ? (
                                                    <SucessoMsg mensagem="imagens adicionadas!" />
                                                ) : (
                                                    <></>
                                                )}

                                            </div>
                                            <br />
                                            <br />
                                        </>
                                    )}
                                    <div className="grupo-imagens-do-carro">
                                        <h2>Fotografias da Viatura:</h2>
                                        <hr className="linha-principal" />
                                        <br />
                                        <div className="imagens-do-carro">
                                            <Image.PreviewGroup>
                                                {conjuntodeimagens && conjuntodeimagens.length > 0 ? (
                                                    fotografiassss?.map((foto) => (
                                                        <>
                                                            <div className="sec-image-single">
                                                                <Image
                                                                    height={200}
                                                                    className="imagem-do-carro"
                                                                    key={foto.mediaItemId}
                                                                    src={foto.sourceUrl}
                                                                    alt={`Imagem ${foto.mediaItemId}`}
                                                                    value={foto.mediaItemId}
                                                                />

                                                                <a className="eliminar-imagem" value={foto.mediaItemId} onClick={handleEliminarFoto}><CloseCircleOutlined /></a>
                                                            </div>
                                                        </>
                                                    ))
                                                ) : (
                                                    <p className="text-cinza">Sem registos de fotografias.</p>
                                                )}
                                            </Image.PreviewGroup>
                                        </div>
                                    </div>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Vídeos" key="3">
                                    <br />
                                    {cancelamentodareserva === "Cancelado" || cancelamentodareserva === "Fechado" ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <div className="inserir-imagens-grupo">
                                                <input type="file" onChange={handleFileVideoInputChange} ref={inputRef} multiple />
                                                <br />
                                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleEnviarVideos}>
                                                    Adicionar
                                                </Button>

                                                {isLoadingVideos ? (
                                                    <LoadingMsg mensagem="Aguarde..." />
                                                ) : isAlertaVideos ? (
                                                    <AlertaMsg mensagem="selecione um vídeo." />
                                                ) : isFalhaVideos ? (
                                                    <ErroMsg mensagem="tente novamente." />
                                                ) : isSucessoVideos ? (
                                                    <SucessoMsg mensagem="vídeo adicionado!" />
                                                ) : isPesodeCarregamentoVideos ? (
                                                    <PesoMsg mensagem="Só consegue adicicionar vídeos com a capacidade máximo de 100MB." />
                                                ) : (
                                                    <></>
                                                )}


                                            </div>
                                            <br />
                                            <br />
                                        </>
                                    )}

                                    <div className="grupo-videos-do-carro">
                                        <h2>Videos da Viatura:</h2>
                                        <hr className="linha-principal" />
                                        <br />
                                        <div className="videos-do-carro-main">
                                            {conjuntodevideo && conjuntodevideo.length > 0 ? (
                                                videoooos?.map((video) => (

                                                    <>
                                                        <div className="sec-image-single">
                                                            <video className="videos-do-carro" controls key={video.mediaItemId}>
                                                                <source src={video.mediaItemUrl} type="video/mp4" />
                                                            </video>

                                                            <a className="eliminar-imagem" value={video.mediaItemId} onClick={handleEliminarFoto}><CloseCircleOutlined /></a>
                                                        </div>
                                                    </>

                                                ))
                                            ) : (
                                                <p className="text-cinza">Sem registos de vídeos.</p>
                                            )}
                                        </div>
                                    </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Histórico" key="4">
                                    <br />
                                    <div>
                                        {isTabletMobile ? (
                                            <>
                                                <Table
                                                    dataSource={logsData}
                                                    pagination={{
                                                        pageSize: 10,
                                                        position: ["bottomCenter"],
                                                    }}
                                                >
                                                    <Table.Column
                                                        title="Nome / Data / Descrição"
                                                        dataIndex="user_name"
                                                        key="user_name"
                                                        render={(user_name, record) => (
                                                            <>
                                                                <strong>Nome:</strong> {user_name}
                                                                <br />
                                                                <strong>Data:</strong> {record.formattedDate}
                                                                <br />
                                                                <strong>Descrição:</strong> {record.description}
                                                            </>
                                                        )}
                                                    />
                                                </Table>
                                            </>
                                        ) : (
                                            <>
                                                <Table
                                                    dataSource={logsData}
                                                    pagination={{
                                                        pageSize: 10,
                                                        position: ["bottomCenter"],
                                                    }}
                                                >
                                                    <Table.Column
                                                        title="Nome"
                                                        dataIndex="user_name"
                                                        key="user_name"
                                                        render={(user_name) => (
                                                            <span>{user_name}</span>
                                                        )}
                                                    />
                                                    <Table.Column
                                                        title="Data"
                                                        dataIndex="formattedDate"
                                                        key="formattedDate"
                                                        render={(formattedDate) => (
                                                            <span>{formattedDate}</span>
                                                        )}
                                                    />
                                                    <Table.Column
                                                        title="Descrição"
                                                        dataIndex="description"
                                                        key="description"
                                                        render={(description) => (
                                                            <span>{description}</span>
                                                        )}
                                                    />
                                                </Table>

                                            </>
                                        )}
                                    </div>
                                </Tabs.TabPane>
                            </>
                        )}

                    </Tabs >

                </>
            )}


        </>
    );
}

export default AreaGestaoReservaAdmin;






