import React, { useState } from 'react';
import { Tabs, Table, Spin, Button } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined, CalendarOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

const TabDashboardCliente = ({ isTabletMobile, searchFilterSempre, searchQuery, handleInputChange, smoothScrollToTop }) => {



    return (
        <>

            <Tabs>
                <Tabs.TabPane tab="Reservas" key="1">
                    <div>
                        <input
                            type="text"
                            className="input-geral"
                            placeholder="Pesquisar por id da reserva, nome ou matrícula..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                    </div>
                    <br />

                    {isTabletMobile ? (
                        <>
                            {searchFilterSempre.length > 0 ? (
                                <>
                                    <Table
                                        dataSource={searchFilterSempre}
                                        rowKey={(reservation) => reservation.ID}
                                        pagination={{
                                            pageSize: 10,
                                            position: ["bottomCenter"],
                                        }}

                                    >
                                        <Table.Column
                                            title="ID / Estado / Nome / Datas / Dados da Viatura"
                                            dataIndex="id"
                                            key="id"
                                            render={(id, reservation) => (
                                                <>
                                                    <Link className="link-label-dashboard" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                                        <strong>Viatura:</strong> {reservation.acf.marca} {reservation.acf.modelo !== 'a:0:{}' ? reservation.acf.modelo: ''} | {reservation.acf.matricula}
                                                        <br></br>
                                                        <span className="link-label">#{reservation.ID}</span> - <span className={`estado-reserva ${reservation.acf.estadodareserva.toLowerCase()}`}>{reservation.acf.estadodareserva}</span>
                                                        <br></br>
                                                        <strong>Nome:</strong> {reservation.acf.primeiro_nome} {reservation.acf.ultimo_nome}
                                                        <br></br>
                                                    </Link>
                                                    <strong>Contacto:</strong> <a className='link-label' href={`tel:${reservation.acf.telemovel}`}>{reservation.acf.telemovel}</a>
                                                    <Link className="link-label-dashboard" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                                        <br></br>
                                                        <strong>Entrada:</strong> {reservation.acf.dia_de_entrada} - {reservation.acf.horario_de_entrada}
                                                        <br></br>
                                                        <strong>Recolha:</strong> {reservation.acf.dia_de_saida} - {reservation.acf.horario_de_saida}
                                                    </Link>
                                                </>
                                            )}
                                        />

                                        {/* <Table.Column
                                            width={40}
                                            title="Entrada"
                                            key="dataentrada"
                                            render={(reservation) => (
                                                `${reservation.acf.dia_de_entrada} - ${reservation.acf.horario_de_entrada}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Saída"
                                            key="datasaida"
                                            render={(reservation) => (
                                                `${reservation.acf.dia_de_saida} - ${reservation.acf.horario_de_saida}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Carro"
                                            key="marcaematricula"
                                            render={(reservation) => (
                                                `${reservation.acf.marca} ${reservation.acf.modelo} | ${reservation.acf.matricula}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Estado"
                                            key="estado"
                                            render={(reservation) => (
                                                <span className={`estado-reserva ${reservation.acf.estadodareserva.toLowerCase()}`}>
                                                    {reservation.acf.estadodareserva}
                                                </span>
                                            )} 
                                        />*/}
                                    </Table>
                                </>
                            ) : (
                                <>
                                    <div className="loading-sec-baixo mensagem-de-sucesso fundo-branco">
                                        <p>Aguarde...</p>
                                        <Spin indicator={antIcon} />
                                    </div>
                                </>

                            )}
                        </>
                    ) : (
                        <>
                            {searchFilterSempre.length > 0 ? (
                                <>
                                    <Table
                                        dataSource={searchFilterSempre}
                                        rowKey={(reservation) => reservation.ID}
                                        pagination={{
                                            pageSize: 5,
                                            position: ["bottomCenter"],
                                        }}
                                    >
                                        <Table.Column
                                            title="ID"
                                            dataIndex="id"
                                            key="id"
                                            render={(id, reservation) => (
                                                <Link className="link-label" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                                    #{reservation.ID}
                                                </Link>
                                            )}
                                        />
                                        <Table.Column
                                            title="Nome"
                                            key="nome"
                                            render={(reservation) => (
                                                `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Entrada"
                                            key="dataentrada"
                                            render={(reservation) => (
                                                `${reservation.acf.dia_de_entrada} - ${reservation.acf.horario_de_entrada}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Saída"
                                            key="datasaida"
                                            render={(reservation) => (
                                                `${reservation.acf.dia_de_saida} - ${reservation.acf.horario_de_saida}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Carro"
                                            key="marcaematricula"
                                            render={(reservation) => (
                                                `${reservation.acf.marca} ${reservation.acf.modelo !== 'a:0:{}' ? reservation.acf.modelo: ''} | ${reservation.acf.matricula}`
                                            )}
                                        />
                                        <Table.Column
                                            title="Estado"
                                            key="estado"
                                            render={(reservation) => (
                                                <span className={`estado-reserva ${reservation.acf.estadodareserva.toLowerCase()}`}>
                                                    {reservation.acf.estadodareserva}
                                                </span>
                                            )}
                                        />
                                    </Table>
                                </>
                            ) : (
                                <>
                                    <div className="loading-sec-baixo mensagem-de-sucesso fundo-branco">
                                        <p>Aguarde...</p>
                                        <Spin indicator={antIcon} />
                                    </div>
                                </>

                            )}

                        </>
                    )}



                </Tabs.TabPane>

            </Tabs>
        </>
    );
};

export default TabDashboardCliente;
