import React, { useState } from "react";
import axios from "axios";
import { Select, Modal, Dropdown, Space, Drawer } from 'antd';
import configAdmin from "../../config-admin/config-admin";
import WPAPI from 'wpapi';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { PiPencilBold, PiPencilSlashBold } from "react-icons/pi";
import { Input } from 'antd';
import { LoadingMsg } from "../mensagens-alertas/loading";
import { AlertaMsg } from "../mensagens-alertas/mensagemdealerta";
import { ErroMsg } from "../mensagens-alertas/mensagemdeerro";
import { SucessoMsg } from "../mensagens-alertas/mensagemdesucesso";
const { Option } = Select;
const { TextArea } = Input;




const AlterarEstadoDaReserva = ({
    idDaReservaPost,
    alterarestadodareserva,
    setAlterarestadodareserva,
    motivocancelarestadodareserva,
    setMotivocancelarestadodareserva,
    desejaadicionarmotivo,
    setDesejaadicionarmotivo,
    setDesejaeditarmotivo,
    desejaeditarmotivo,
    userMain,
    id,
    setDataReserva,
    // refetch,
    setLogsData,
    extraDataServicosReserva
}) => {

    const navigate = useNavigate();

    const userFromLocalStorage = localStorage.getItem('user');
    const userRole = userMain?.roles[0]
    const userNameAccount = userMain?.data.user_nicename
    const dadosdofuncionarioparalogs = userMain?.data





    //ALTERAR ESTADO DA RESERVA
    const [isModalOpenAlterarEstado, setIsModalOpenAlterarEstado] = useState(false);
    const [isModalOpenEstadodaReserva, setIsModalOpenEstadodaReserva] = useState(false);


    //METODO DE PAGAMENTO
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
  
    const todayDateString = `${dd}-${mm}-${yyyy}`;


    const [postAtualizarEstadoPagamento, setPostAtualizarEstadoPagamento] = useState(false);
    const [isModalOpenEstadodePagamento, setIsModalOpenEstadodePagamento] = useState(false);

    const [dataalteracaoEstadodePagamentodaReserva, setDataalteracaoEstadodePagamentodaReserva] = useState(todayDateString);
    const [novoEstadodePagamentodaReserva, setNovoEstadodePagamentodaReserva] = useState(false);
    const [novoEstadodeMetodoPagamentodaReserva, setIsModalOpenMetodoEstadodePagamento] = useState(false);




    //EstadodaReserva-LOADING
    const [isLoadingEstado, setIsLoadingEstado] = useState(false);
    const [isSucessoEstado, setIsSucessoEstado] = useState(false);
    const [isAlertaEstado, setIsAlertaEstado] = useState(false);
    const [isFalhaEstado, setIsFalhaEstado] = useState(false);

    //MotivoCancelamento-LOADING
    const [isLoadingMotivoCancelamento, setIsLoadingMotivoCancelamento] = useState(false);
    const [isSucessoMotivoCancelamento, setIsSucessoMotivoCancelamento] = useState(false);
    const [isAlertaMotivoCancelamento, setIsAlertaMotivoCancelamento] = useState(false);
    const [isFalhaMotivoCancelamento, setIsFalhaMotivoCancelamento] = useState(false);

    //MotivoCancelamento-LOADING
    const [isLoadingEstadodePagamento, setIsLoadingEstadodePagamento] = useState(false);
    const [isSucessoEstadodePagamento, setIsSucessoEstadodePagamento] = useState(false);
    const [isAlertaEstadodePagamento, setIsAlertaEstadodePagamento] = useState(false);
    const [isFalhaEstadodePagamento, setIsFalhaEstadodePagamento] = useState(false);
    const [alertaMensagemEstadoPagamento, setAlertaMensagemEstadoPagamento] = useState(false);




    // const [open, setOpen] = useState(false);
    // const showDrawer = () => {
    //     setOpen(true);
    // };
    // const onClose = () => {
    //     setOpen(false);
    // };



    // ALTERAR ESTADO DA RESERVA
    const handleEstadodaReserva = async (novoEstadodaReserva) => {
        if (novoEstadodaReserva === 'Cancelado') {

            setIsModalOpenAlterarEstado(true);

        } else {
            setIsModalOpenAlterarEstado(false);

            setIsAlertaEstado(false)
            setIsLoadingEstado(true);
            setIsSucessoEstado(false);
            setIsFalhaEstado(false);

            const headers = new Headers(configAdmin.headers);

            // Acessos
            const wp = new WPAPI({
                endpoint: configAdmin.wpApi.endpoint,
                username: configAdmin.wpApi.username,
                password: configAdmin.wpApi.password,
                headers: headers
            });

            wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');

            try {
                setAlterarestadodareserva(novoEstadodaReserva);

                if (novoEstadodaReserva) {
                    const atualizar = await wp.reservas().id(idDaReservaPost).update({
                        acf: {
                            estadodareserva: novoEstadodaReserva,
                        },
                    });

                    let enviarlogsdata;

                    enviarlogsdata = {
                        id_reserva: idDaReservaPost,
                        description: `Alterou o Estado da Reserva para: ${novoEstadodaReserva}`,
                        tipo: "estado",
                        user_id: dadosdofuncionarioparalogs.ID,
                        user_name: dadosdofuncionarioparalogs.display_name,
                        update: `${novoEstadodaReserva}`,
                        motivo: ""
                    };

                    setIsAlertaEstado(false)
                    setIsLoadingEstado(false);
                    setIsSucessoEstado(true);
                    setIsFalhaEstado(false);

                    setTimeout(() => {
                        setIsModalOpenEstadodaReserva(false);
                        setAlterarestadodareserva(false)
                        setIsAlertaEstado(false)
                        setIsLoadingEstado(false);
                        setIsSucessoEstado(false);
                        setIsFalhaEstado(false);
                    }, 1000);

                    axios
                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/post_log', enviarlogsdata)
                        .then(response => {
                            const modeValidation = response.data.validation;
                            if (modeValidation === "valid") {


                                let obterdatareservas;

                                obterdatareservas = {
                                    hash: id,
                                }

                                axios
                                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                    .then(response => {
                                        const detalhesDaReserva = response.data
                                        // console.log(detalhesDaReserva)
                                        setDataReserva(detalhesDaReserva)
                                    })
                                    .catch(error => {
                                        console.error('Erro:', error);
                                    })



                                axios
                                    .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                    .then((response) => {
                                        // console.log(response)
                                        const alllogsData = response.data;
                                        setLogsData(alllogsData);
                                    })
                                    .catch((error) => {
                                        console.error('Erro:', error);
                                    });

                            } else {
                                console.error('Erro:');
                            }

                            setIsAlertaEstado(false)
                            setIsLoadingEstado(false);
                            setIsSucessoEstado(true);
                            setIsFalhaEstado(false);

                        })
                        .catch(error => {
                            console.error('Erro:', error);
                            setIsAlertaEstado(false)
                            setIsLoadingEstado(false);
                            setIsSucessoEstado(false);
                            setIsFalhaEstado(true);
                        })


                } else {
                    setIsAlertaEstado(false)
                    setIsLoadingEstado(false);
                    setIsSucessoEstado(false);
                    setIsFalhaEstado(true);
                    //console.log('Erro');
                }
            } catch (error) {
                console.error('Erro:', error);
                setIsAlertaEstado(false)
                setIsLoadingEstado(false);
                setIsSucessoEstado(false);
                setIsFalhaEstado(true);
            }
        }
    };

    //ENVIAR MOTIVO
    const handleEnviarMotivo = async (novoEstadodaReserva) => {

        if (motivocancelarestadodareserva === null || motivocancelarestadodareserva === "") {
            setIsAlertaMotivoCancelamento(true)
            setIsLoadingMotivoCancelamento(false);
            setIsSucessoMotivoCancelamento(false);
            setIsFalhaMotivoCancelamento(false);
            return
        } else {

            setIsAlertaMotivoCancelamento(false)
            setIsLoadingMotivoCancelamento(true);
            setIsSucessoMotivoCancelamento(false);
            setIsFalhaMotivoCancelamento(false);

            const headers = new Headers(configAdmin.headers);

            // Acessos
            const wp = new WPAPI({
                endpoint: configAdmin.wpApi.endpoint,
                username: configAdmin.wpApi.username,
                password: configAdmin.wpApi.password,
                headers: headers
            });

            wp.reservas = wp.registerRoute('wp/v2', 'reservas/(?P<id>\\d+)');

            try {
                if (novoEstadodaReserva) {
                    // Atualize a reserva no WordPress com o novo motivo
                    const atualizar = await wp.reservas().id(idDaReservaPost).update({
                        acf: {
                            motivoestadodareserva: motivocancelarestadodareserva,
                            estadodareserva: alterarestadodareserva,
                        },
                    });
                    // console.log('sucesso:', atualizar);

                    setDesejaeditarmotivo(true)
                    setDesejaadicionarmotivo(false)



                    let enviarlogsdata;

                    enviarlogsdata = {
                        id_reserva: idDaReservaPost,
                        description: `Alterou o Estado da Reserva para: Cancelado`,
                        tipo: "estado",
                        user_id: dadosdofuncionarioparalogs.ID,
                        user_name: dadosdofuncionarioparalogs.display_name,
                        update: `Cancelado`,
                        motivo: `${motivocancelarestadodareserva}`,
                    };

                    axios
                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/post_log', enviarlogsdata)
                        .then(response => {
                            const modeValidation = response.data.validation;
                            if (modeValidation === "valid") {
                                axios
                                    .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                    .then((response) => {
                                        // console.log(response)
                                        const alllogsData = response.data;
                                        setLogsData(alllogsData);
                                    })
                                    .catch((error) => {
                                        console.error('Erro:', error);
                                    });

                            } else {
                                console.error('Erro:');
                            }
                            setIsAlertaMotivoCancelamento(false)
                            setIsLoadingMotivoCancelamento(false);
                            setIsSucessoMotivoCancelamento(true);
                            setIsFalhaMotivoCancelamento(false);

                        })
                        .catch(error => {
                            console.error('Erro:', error);
                            setIsAlertaMotivoCancelamento(false)
                            setIsLoadingMotivoCancelamento(false);
                            setIsSucessoMotivoCancelamento(false);
                            setIsFalhaMotivoCancelamento(true);
                        })



                } else {
                    //console.log('Erro');
                    setIsAlertaMotivoCancelamento(false)
                    setIsLoadingMotivoCancelamento(false);
                    setIsSucessoMotivoCancelamento(false);
                    setIsFalhaMotivoCancelamento(true);
                }
            } catch (error) {
                console.error('Erro:', error);
                setIsAlertaMotivoCancelamento(false)
                setIsLoadingMotivoCancelamento(false);
                setIsSucessoMotivoCancelamento(false);
                setIsFalhaMotivoCancelamento(true);
            }
        }


    };




    const handleOk = async () => {
        setIsModalOpenAlterarEstado(false);
        setAlterarestadodareserva("Cancelado");
    };


    const handleCancel = () => {
        setIsModalOpenAlterarEstado(false);
    };


    //MOSTRAR TEXTOS
    const handleShowTextMotivo = () => {
        setDesejaadicionarmotivo(!desejaadicionarmotivo)
    }






    // ALTERAR ESTADO DA RESERVA
    const handleEditarEstadoDaReserva = async () => {
        setIsModalOpenEstadodaReserva(true);
    };

    const handleCancelEstadoDaReserva = () => {
        setIsModalOpenEstadodaReserva(false);
    };






    // ALTERAR ESTADO DE PAGAMENTO DA RESERVA
    const handleEditarEstadoDePagamento = async () => {
        setIsModalOpenEstadodePagamento(true);
        setNovoEstadodePagamentodaReserva('')
        setDataalteracaoEstadodePagamentodaReserva(dataalteracaoEstadodePagamentodaReserva)
        setIsModalOpenMetodoEstadodePagamento('')
    };

    const handleCancelEstadoDePagamento = () => {
        setIsModalOpenEstadodePagamento(false);
    };


    // console.log('novoEstadodeMetodoPagamentodaReserva', novoEstadodeMetodoPagamentodaReserva)
    // console.log('dataalteracaoEstadodePagamentodaReserva', dataalteracaoEstadodePagamentodaReserva)
    // console.log('novoEstadodePagamentodaReserva', novoEstadodePagamentodaReserva)


    const handleOkEstadoDePagamento = async () => {


        setPostAtualizarEstadoPagamento(true);

        setIsSucessoEstadodePagamento(false);
        setIsLoadingEstadodePagamento(true);
        setIsAlertaEstadodePagamento(false);
        setIsFalhaEstadodePagamento(false);


        if (!postAtualizarEstadoPagamento) {

            if (novoEstadodeMetodoPagamentodaReserva === false || dataalteracaoEstadodePagamentodaReserva === "" || novoEstadodePagamentodaReserva === false) {
                setIsSucessoEstadodePagamento(false);
                setIsLoadingEstadodePagamento(false);
                setIsAlertaEstadodePagamento(true);
                setIsFalhaEstadodePagamento(false);
                setPostAtualizarEstadoPagamento(false);
                setAlertaMensagemEstadoPagamento("preencha os campos.");
                return
            } else {

                let enviarnovometododepagamento

                enviarnovometododepagamento = {
                    estado_pagamento: novoEstadodePagamentodaReserva,
                    data_pagamento: dataalteracaoEstadodePagamentodaReserva,
                    metodo: novoEstadodeMetodoPagamentodaReserva,
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_estado_pagamento", enviarnovometododepagamento)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoEstadodePagamento(true);
                            setIsLoadingEstadodePagamento(false);
                            setIsAlertaEstadodePagamento(false);
                            setIsFalhaEstadodePagamento(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {

                                    let obterdatareservas;

                                    obterdatareservas = {
                                        hash: id,
                                    }
    
                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })
    

                                    // console.log(response)
                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenEstadodePagamento(false);
                                        setPostAtualizarEstadoPagamento(false);
                                        setIsSucessoEstadodePagamento(false);
                                        setIsLoadingEstadodePagamento(false);
                                        setIsAlertaEstadodePagamento(false);
                                        setIsFalhaEstadodePagamento(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoEstadodePagamento(false);
                                    setIsLoadingEstadodePagamento(false);
                                    setIsAlertaEstadodePagamento(false);
                                    setIsFalhaEstadodePagamento(true);
                                    setPostAtualizarEstadoPagamento(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoEstadodePagamento(false);
                            setIsLoadingEstadodePagamento(false);
                            setIsAlertaEstadodePagamento(false);
                            setIsFalhaEstadodePagamento(true);
                            setPostAtualizarEstadoPagamento(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoEstadodePagamento(false);
                        setIsLoadingEstadodePagamento(false);
                        setIsAlertaEstadodePagamento(false);
                        setIsFalhaEstadodePagamento(true);
                        setPostAtualizarEstadoPagamento(false);
                    })
            }
        }


    };





    return (
        <>
            <div className="admin-lab">
                <div className="bloco-admin-alterarreserva">
                    <div className="estadosdareserva-sec">
                        {alterarestadodareserva === "Cancelado" ? (
                            <>
                                {desejaeditarmotivo ? (
                                    <>
                                        <div className="modal-com-flex">
                                            {userRole === "administrator" || userRole === "shop_manager" ? (
                                                <>
                                                    <Select
                                                        id="estados"
                                                        value={alterarestadodareserva}
                                                        onChange={(novoEstadodaReserva) => handleEstadodaReserva(novoEstadodaReserva)}
                                                        placeholder="Estado da Reserva"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabled={(alterarestadodareserva === "Reserva" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0")) || (alterarestadodareserva === "Aberto" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0"))}
                                                    >
                                                        <Option value="Reserva">Reserva</Option>
                                                        <Option value="Aberto">Aberto</Option>
                                                        <Option value="Fechado">Fechado</Option>
                                                        <Option value="Cancelado">Cancelado</Option>
                                                    </Select>
                                                </>
                                            ) : (
                                                <>
                                                    <Select
                                                        id="estados"
                                                        value={alterarestadodareserva}
                                                        onChange={(novoEstadodaReserva) => handleEstadodaReserva(novoEstadodaReserva)}
                                                        placeholder="Estado da Reserva"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabled={(alterarestadodareserva === "Cancelado" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0")) || (alterarestadodareserva === "Aberto" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0")) || (alterarestadodareserva === "Reserva" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0"))}
                                                    // disabled={alterarestadodareserva === "Cancelado" || (alterarestadodareserva === "Reserva" && extraDataServicosReserva.length > 1 && extraDataServicosReserva.some(servico => servico.pago === "0"))}
                                                    >
                                                        <Option value="Reserva">Reserva</Option>
                                                        <Option value="Aberto">Aberto</Option>
                                                        <Option value="Fechado">Fechado</Option>
                                                        <Option value="Cancelado">Cancelado</Option>
                                                    </Select>
                                                </>
                                            )}
                                            <div>
                                                {isLoadingEstado ? (
                                                    <>
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    </>
                                                ) : isAlertaEstado ? (
                                                    <>
                                                        <AlertaMsg mensagem="selecione um estado" />
                                                    </>
                                                ) : isFalhaEstado ? (
                                                    <>
                                                        <ErroMsg mensagem="tente novamente." />
                                                    </>
                                                ) : isSucessoEstado ? (
                                                    <>
                                                        <SucessoMsg mensagem="estado atualizado!" />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                        <div className="motivo-do-cancelamento-box-cancleado">
                                            <a><b>Motivo do Estado "Cancelado":</b></a>
                                            <hr className="linha-principal" />
                                            <div className="bloco-motivodocancelamento">
                                                <p>{motivocancelarestadodareserva}</p>
                                                {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                    <>
                                                        {/* {motivocancelarestadodareserva !== "" ? (
                                                                    <> */}
                                                        {desejaadicionarmotivo ? (
                                                            <div>
                                                                <Button className="edit-form-button" type="primary" icon={<PiPencilSlashBold />} onClick={handleShowTextMotivo}>
                                                                    Fechar Edição
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <Button className="edit-form-button" type="primary" icon={<PiPencilBold />} onClick={handleShowTextMotivo}>
                                                                    Editar
                                                                </Button>
                                                            </div>
                                                        )}
                                                        {/* </> 
                                                                // ) : (
                                                                //     <>
                                                                //     </>
                                                                // )}*/}
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )}

                                            </div>

                                        </div>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}

                                {desejaadicionarmotivo ? (
                                    <div className="motivo-do-cancelamento">
                                        <TextArea
                                            placeholder='Escreva o motivo de cancelamento da reserva…'
                                            rows="3"
                                            cols="33"
                                            value={motivocancelarestadodareserva}
                                            onChange={(e) => setMotivocancelarestadodareserva(e.target.value)}
                                        />
                                        <br />
                                        <br />
                                        <div className="flex state-cancelado">
                                            {desejaeditarmotivo ? (
                                                <>
                                                    <Button type="primary" onClick={handleEnviarMotivo} className="atualizar-form-button">
                                                        Atualizar
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button type="primary" onClick={handleEnviarMotivo} className="cancelar-form-button">
                                                        Guardar
                                                    </Button>
                                                </>
                                            )}
                                            {isLoadingMotivoCancelamento ? (
                                                <LoadingMsg mensagem="Aguarde..." />
                                            ) : isAlertaMotivoCancelamento ? (
                                                <AlertaMsg mensagem="deixe uma observação." />
                                            ) : isFalhaMotivoCancelamento ? (
                                                <ErroMsg mensagem="tente novamente." />
                                            ) : isSucessoMotivoCancelamento ? (
                                                <SucessoMsg mensagem="estado atualizado!" />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                    </>
                                )}





                            </>
                        ) : (
                            <>
                                <div className="estadosdareserva-col-btn">
                                    <Button type="primary" onClick={handleEditarEstadoDaReserva} className="admin-form-button-n">
                                        <PiPencilBold style={{ marginRight: '8px' }} /> Alterar Estado
                                    </Button>
                                    <Modal
                                        title="Alterar Estado"
                                        open={isModalOpenEstadodaReserva}
                                        footer={null}
                                        // onOk={handleOkEstadoDaReserva}
                                        onCancel={handleCancelEstadoDaReserva}
                                        okText="Confirmar"
                                        cancelText="Cancelar"
                                    >
                                        <div className="modal-com-flex">
                                            {userRole === "administrator" || userRole === "shop_manager" ? (
                                                <>
                                                    <Select
                                                        id="estados"
                                                        value={alterarestadodareserva}
                                                        onChange={(novoEstadodaReserva) => handleEstadodaReserva(novoEstadodaReserva)}
                                                        placeholder="Estado da Reserva"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabled={(alterarestadodareserva === "Reserva" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0")) || (alterarestadodareserva === "Aberto" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0"))}
                                                    >
                                                        <Option value="Reserva">Reserva</Option>
                                                        <Option value="Aberto">Aberto</Option>
                                                        <Option value="Fechado">Fechado</Option>
                                                        <Option value="Cancelado">Cancelado</Option>
                                                    </Select>
                                                </>
                                            ) : (
                                                <>
                                                    <Select
                                                        id="estados"
                                                        value={alterarestadodareserva}
                                                        onChange={(novoEstadodaReserva) => handleEstadodaReserva(novoEstadodaReserva)}
                                                        placeholder="Estado da Reserva"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabled={(alterarestadodareserva === "Cancelado" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0")) || (alterarestadodareserva === "Aberto" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0")) || (alterarestadodareserva === "Reserva" && extraDataServicosReserva.length > 0 && extraDataServicosReserva.some(servico => servico.pago === "0"))}
                                                    // disabled={alterarestadodareserva === "Cancelado" || (alterarestadodareserva === "Reserva" && extraDataServicosReserva.length > 1 && extraDataServicosReserva.some(servico => servico.pago === "0"))}
                                                    >
                                                        <Option value="Reserva">Reserva</Option>
                                                        <Option value="Aberto">Aberto</Option>
                                                        <Option value="Fechado">Fechado</Option>
                                                        <Option value="Cancelado">Cancelado</Option>
                                                    </Select>
                                                </>
                                            )}
                                            <div>
                                                {isLoadingEstado ? (
                                                    <>
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    </>
                                                ) : isAlertaEstado ? (
                                                    <>
                                                        <AlertaMsg mensagem="selecione um estado" />
                                                    </>
                                                ) : isFalhaEstado ? (
                                                    <>
                                                        <ErroMsg mensagem="tente novamente." />
                                                    </>
                                                ) : isSucessoEstado ? (
                                                    <>
                                                        <SucessoMsg mensagem="estado atualizado!" />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                        <Modal
                                            title="Alterar Estado para 'Cancelado'"
                                            open={isModalOpenAlterarEstado}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                        >
                                            <p>Deseja confirmar a alteração do estado da reserva para "Cancelado"?</p>
                                        </Modal>
                                        {alterarestadodareserva === "Cancelado" && (
                                            <>
                                                {desejaeditarmotivo ? (
                                                    <>
                                                        <div className="motivo-do-cancelamento-box">
                                                            <a><b>Motivo do Estado "Cancelado":</b></a>
                                                            <hr className="linha-principal" />
                                                            <div className="bloco-motivodocancelamento">
                                                                <p>{motivocancelarestadodareserva}</p>
                                                                {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                                    <>
                                                                        {desejaadicionarmotivo ? (
                                                                            <div>
                                                                                <Button className="edit-form-button" type="primary" icon={<PiPencilSlashBold />} onClick={handleShowTextMotivo}>
                                                                                    Fechar Edição
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <Button className="edit-form-button" type="primary" icon={<PiPencilBold />} onClick={handleShowTextMotivo}>
                                                                                    Editar
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    </>
                                                                )}

                                                            </div>

                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )}

                                                {desejaadicionarmotivo ? (
                                                    <div className="motivo-do-cancelamento">
                                                        <TextArea
                                                            placeholder='Escreva o motivo de cancelamento da reserva…'
                                                            rows="3"
                                                            cols="33"
                                                            value={motivocancelarestadodareserva}
                                                            onChange={(e) => setMotivocancelarestadodareserva(e.target.value)} // Use e.target.value para obter o valor do textarea
                                                        />
                                                        <br />
                                                        <br />
                                                        <div className="flex state-cancelado">
                                                            {desejaeditarmotivo ? (
                                                                <>
                                                                    <Button type="primary" onClick={handleEnviarMotivo} className="atualizar-form-button">
                                                                        Atualizar
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button type="primary" onClick={handleEnviarMotivo} className="cancelar-form-button">
                                                                        Guardar
                                                                    </Button>
                                                                </>
                                                            )}
                                                            {isLoadingMotivoCancelamento ? (
                                                                <LoadingMsg mensagem="Aguarde..." />
                                                            ) : isAlertaMotivoCancelamento ? (
                                                                <AlertaMsg mensagem="deixe uma observação." />
                                                            ) : isFalhaMotivoCancelamento ? (
                                                                <ErroMsg mensagem="tente novamente." />
                                                            ) : isSucessoMotivoCancelamento ? (
                                                                <SucessoMsg mensagem="estado atualizado!" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                    </>
                                                )}




                                            </>
                                        )}
                                    </Modal>
                                    <Button type="primary" onClick={handleEditarEstadoDePagamento} className="admin-form-button-n">
                                        <PiPencilBold style={{ marginRight: '8px' }} /> Alterar Estado de Pagamento
                                    </Button>
                                    <Modal
                                        title="Alterar Estado de Pagamento"
                                        open={isModalOpenEstadodePagamento}
                                        onOk={handleOkEstadoDePagamento}
                                        onCancel={handleCancelEstadoDePagamento}
                                        okText="Confirmar"
                                        cancelText="Cancelar"
                                    >
                                        <div className="modal-com-flex">
                                            <div>
                                                <span>Estado do Pagamento</span>
                                                <Select
                                                    id="estados"
                                                    value={novoEstadodePagamentodaReserva}
                                                    onChange={(e) => setNovoEstadodePagamentodaReserva(e)}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Option value="Aguardar Pagamento">Aguardar Pagamento</Option>
                                                    <Option value="Pago">Pago</Option>
                                                    <Option value="Pago Saída">Pago Saída</Option>
                                                    <Option value="Não Pago">Não Pago</Option>
                                                </Select>
                                            </div>
                                            <div>
                                                <span>Data do Pagamento</span>
                                                <label>
                                                    <input
                                                        type="text"
                                                        className={`input-calendario`}
                                                        placeholder="dd-mm-aaaa"
                                                        // value={today_date_formated}
                                                        value={dataalteracaoEstadodePagamentodaReserva}
                                                        onChange={(e) => setDataalteracaoEstadodePagamentodaReserva(e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                            <div>
                                                <span>Método do Pagamento</span>
                                                <Select
                                                    id="estados"
                                                    placeholder="Método de Pagamento"
                                                    value={novoEstadodeMetodoPagamentodaReserva}
                                                    onChange={(e) => setIsModalOpenMetodoEstadodePagamento(e)}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Option value="Pagamento no Local / Numerário">Pagamento no Local / Numerário</Option>
                                                    <Option value="Pagamento no Local / MBWay">Pagamento no Local / MBWay</Option>
                                                    <Option value="Pagamento de Serviços no Multibanco">Pagamento de Serviços no Multibanco</Option>
                                                    <Option value="MBWAY">MBWAY</Option>
                                                    <Option value="Cartão de Crédito / Débito">Cartão de Crédito / Débito</Option>
                                                    <Option value="Transferência Bancária">Transferência Bancária</Option>

                                                </Select>
                                            </div>
                                            {isLoadingEstadodePagamento ? (
                                                <>
                                                    <LoadingMsg mensagem="Aguarde..." />
                                                </>
                                            ) : isAlertaEstadodePagamento ? (
                                                <>
                                                    <AlertaMsg mensagem={alertaMensagemEstadoPagamento} />
                                                </>
                                            ) : isFalhaEstadodePagamento ? (
                                                <>
                                                    <ErroMsg mensagem="tente novamente." />
                                                </>
                                            ) : isSucessoEstadodePagamento ? (
                                                <>
                                                    <SucessoMsg mensagem="dados de pagamaneto atualizados!" />
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Modal>
                                </div>

                            </>
                        )}
                    </div>
                </div>

            </div >
        </>
    );
};

export default AlterarEstadoDaReserva;
